import React from "react";
import { Script } from "gatsby";

const HeaderScripts = () => {
  return (
    <>
      {/* Vidazoo */}
      <Script
        id="vidazooJS"
        src="//static.vidazoo.com/basev/vwpt.js"
        data-widget-id="627a76c6640cfdd4cb6a13ec"
      />

      {/* Amazon */}
      <Script
        dangerouslySetInnerHTML={{
          __html: `!function(a9,a,p,s,t,A,g){if(a[a9])return;function q(c,r){a[a9]._Q.push([c,r])}a[a9]={init:function(){q("i",arguments)},fetchBids:function(){q("f",arguments)},setDisplayBids:function(){},targetingKeys:function(){return[]},_Q:[]};A=p.createElement(s);A.async=!0;A.src=t;g=p.getElementsByTagName(s)      [0];g.parentNode.insertBefore(A,g)}("apstag",window,document,"script","//c.amazon-adsystem.com/aax2/apstag.js");`,
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `apstag.init({pubID: 'a9191d6c-d49e-4df3-8f95-80472ace83e7',adServer: 'googletag',});`,
        }}
      />

      {/* Block Through */}
      <Script src="//btloader.com/tag?o=5655014103056384&upapi=true" async />

      {/*CPM Events*/}
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          function getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
            }
            }
            return "";
          }
        `,
        }}
      />
      <Script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
          window.sumCpmEvent = 0;
          window.sumCpmEvent_hb = 0;
          window.sumGA = 0;
          window.bids_added = 0;
          window._winners_calc = {};
          window._auctions_calc = {};
          window._min_uv_goal = 5;
          window._max_uv_goal = 1000;
          if (!isNaN(this.getCookie("sumCpmEvent")) && this.getCookie("sumCpmEvent")) {
            window.sumCpmEvent = parseInt(this.getCookie("sumCpmEvent"));
          } else {
            window.sumCpmEvent = 0;
          }
        `,
        }}
      />

      {/* 
      ----
      Defferd scripts 
      ----
      */}
      {/* Google Analytics */}
      <Script
        strategy="idle"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ID}`}
      />

      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${process.env.GATSBY_GTAG_ID}');`,
        }}
      />

      {/* Google tag for new GDN pixel */}
      <Script
        strategy="idle"
        src={`https://www.googletagmanager.com/gtag/js?id=AW-11127303233`}
      />

      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-11127303233');`,
        }}
      />

      {/* Google tag for new GDN pixel --END */}

      {/* Yahoo */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,t,r,u){w[u]=w[u]||[];w[u].push({'projectId':'10000','properties':{'pixelId':'10068759','userEmail': '<email_address>'}});var s=d.createElement(t);s.src=r;s.async=true;s.onload=s.onreadystatechange=function(){var y,rs=this.readyState,c=w[u];if(rs&&rs!="complete"&&rs!="loaded"){return}try{y=YAHOO.ywa.I13N.fireBeacon;w[u]=[];w[u].push=function(p){y([p])};y(c)}catch(e){}};var scr=d.getElementsByTagName(t)[0],par=scr.parentNode;par.insertBefore(s,scr)})(window,document,"script","https://s.yimg.com/wi/ytc.js","dotq");`,
        }}
      />

      {/* Outbrain English */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `!function(_window, _document) {   var OB_ADV_ID=['004e303155f79517fddf2c1b64884378c7', '001c6e3bacab66aad4f7fad95829b89676', '0007e7dc8869ac59121df5b3d6b4a06a8b', '009312477bb9ddf29dab5147620d3672a6', '002dd420df5a8aac876d18f28c4e67f854', '00ebf6f6ef8f61c7be09c43dd94a0ceb19', '00f2d70235b02e79039543bbd63149b7c8', '004e0ec38fff935a04fca88c518d0abb47', '00f719507f51dc1ee0bf07610c7cadd486', '006c71fd391e9c14b7a1d987aa2311c483', '007849b476e1c590a2155a2967c109c980', '003ef12d9e3a69d12d6525ad3d17fd2c14'];if (_window.obApi) {var toArray = function(object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));return;}var api = _window.obApi = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.1';api.loaded = true;api.marketerId = OB_ADV_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = '//amplify.outbrain.com/cp/obtp.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);}(window, document);obApi('track', 'PAGE_VIEW');`,
        }}
      />

      {/* Outbrain Spanish */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `!function(_window, _document) {var OB_ADV_ID='004e0ec38fff935a04fca88c518d0abb47';if (_window.obApi) {var toArray = function(object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));return;}var api = _window.obApi = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.1';api.loaded = true;api.marketerId = OB_ADV_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = '//amplify.outbrain.com/cp/obtp.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);}(window, document);obApi('track', 'PAGE_VIEW');`,
        }}
      />

      {/* Taboola Pixel Base */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window._tfa = window._tfa || [];window._tfa.push({notify: 'event', name: 'page_view', id: 1286057});!function (t, f, a, x) {if (!document.getElementById(x)) {t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);}}(document.createElement('script'),document.getElementsByTagName('script')[0],'//cdn.taboola.com/libtrc/unip/1286057/tfa.js','tb_tfa_script');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img src='https://trc.taboola.com/1286057/log/3/unip?en=page_view'width='0' height='0' style='display:none'/>`,
        }}
      />

      {/* Taboola Pixel UV2 */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img src='https://trc.taboola.com/1286057/log/3/unip?en=UV2'width='0' height='0' style='display:none'/>`,
        }}
      />

      {/* Taboola Pixel Page View */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window._tfa = window._tfa || [];window._tfa.push({notify: 'event', name: 'page_view', id: 1052791});!function (t, f, a, x) {if (!document.getElementById(x)) {t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);}}(document.createElement('script'),document.getElementsByTagName('script')[0],'//cdn.taboola.com/libtrc/unip/1052791/tfa.js','tb_tfa_script');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img src='https://trc.taboola.com/1052791/log/3/unip?en=page_view'width='0' height='0' style='display:none'/>`,
        }}
      />

      {/* Taboola Pixel UV05 */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img src='https://trc.taboola.com/1052791/log/3/unip?en=UV05'width='0' height='0' style='display:none'/>`,
        }}
      />

      {/* Media Net */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window._mNHandle = window._mNHandle || {};window._mNHandle.queue = window._mNHandle.queue || [];medianet_versionId = "3121199";`,
        }}
      />
      <Script
        strategy="idle"
        src="//contextual.media.net/dmedianet.js?cid=8CU272W16"
        async="async"
      />

      {/* Snapchat pixel */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `(function(e,t,n){if(e.snaptr)return;var a=e.snaptr=function(){a.handleRequest?a.handleRequest.apply(a,arguments):a.queue.push(arguments)};a.queue=[];var s='script';r=t.createElement(s);r.async=!0;r.src=n;var u=t.getElementsByTagName(s)[0];u.parentNode.insertBefore(r,u);})(window,document,'https://sc-static.net/scevent.min.js');snaptr('init', '6fba7528-a848-41b6-9c63-32c6cb7123d0', {'user_email': '__INSERT_USER_EMAIL__'});snaptr('track', 'PAGE_VIEW');`,
        }}
      />

      {/* Pinterest */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `!function(e){if(!window.pintrk){window.pintrk = function () {window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var n=window.pintrk;n.queue=[],n.version="3.0";var t=document.createElement("script");t.async=!0,t.src=e;varr=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js"); pintrk('load', '2612462462250', {em: '<user_email_address>'}); pintrk('page');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none;" alt=""src="https://ct.pinterest.com/v3/?event=init&tid=2612462462250&pd[em]=<hashed_email_address>&noscript=1" />`,
        }}
      />

      {/* GDN Pixels - 573322620, 793126717 */}
      <Script
        strategy="idle"
        src="https://www.googletagmanager.com/gtag/js?id=AW-573322620"
      />
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-573322620');`,
        }}
      />
      <Script
        strategy="idle"
        src="https://www.googletagmanager.com/gtag/js?id=AW-793126717"
      />
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-793126717');`,
        }}
      />

      {/* Facebook Pixel */}
      <Script
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '666307604647013');
            fbq('track', 'PageView');`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=666307604647013&ev=PageView&noscript=1"/>`,
        }}
      />
    </>
  );
};

export default HeaderScripts;
